//COMPONENTS
import Trans from "./Trans.js";
import PurchaseTermsText from "./PurchaseTermsText.js";

function PurchaseTerms({ dealtype, values, onCheckBoxChange, errorMessage }) {
  var quoteTermsClassname = "rounded-border quote-terms";
  var quoteTermsLink = "https://hubs.ly/H0n42zC0";

  switch (dealtype) {
    case "EQUIMETRE FLAT":
      quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre";
      break;
    case "EQUIMETRE TROT":
      quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre";
      break;
    case "EQUIMETRE VET":
      quoteTermsClassname =
        "rounded-border quote-terms quote-terms-equimetre-vet";
      break;
    case "EQUISYM":
      quoteTermsClassname = "rounded-border quote-terms quote-terms-equisym";
      quoteTermsLink = "https://hubs.ly/Q01l4qmf0"
      break;
    default:
      break;
  }

  return (
    <div>
      <PurchaseTermsText dealtype={dealtype}></PurchaseTermsText>
      <div className="fixed-width">
        <div className="hs-form__row">
          <div className="hs-form__group">
            <div className="hs-form__field-row">
              <div className="hs-form__field-row__column">
                <div className="hs-form__field">
                  <div
                    id="purchaseTermsAcceptance-description"
                    className="hs-form__field__label hs-form__field__label_required"
                  >
                    <Trans text="purchase_terms_acceptance_title"></Trans> :
                  </div>
                  <label
                    id="purchaseTermsAcceptance-label"
                    className="hs-form__field__label  hs-form__field__checkbox-label"
                    data-required="true"
                  >
                    <input
                      id="purchaseTermsAcceptance-input"
                      aria-labelledby=" purchaseTermsAcceptance-label"
                      className="hs-form__field__input "
                      type="checkbox"
                      name="purchaseTermsAcceptance"
                      aria-invalid="false"
                      aria-required="true"
                      aria-checked="false"
                      value=""
                      onChange={(e) => onCheckBoxChange(e)}
                    ></input>
                    <span className="hs-form__field__checkbox__label-span">
                      <Trans text="purchase_terms_acceptance_subtitle"></Trans>{" "}
                      <a
                        href={quoteTermsLink}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        <Trans text="purchase_terms_terms_and_conditions"></Trans>
                      </a>
                      .
                    </span>
                  </label>
                  {errorMessage && (
                    <div
                      id="email__error"
                      class="hs-form__field__alert hs-form__field__alert_error"
                    >
                      <span>{errorMessage}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseTerms;
