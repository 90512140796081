import i18n from "../locale/i18n";
import { useState } from "react";
//CSS
import "../styles/App.css";
//COMPONENTS
import Topbar from "./Topbar.js";
import Footer from "./Footer.js";
import ButtonTemplate from "./ButtonTemplate";
import { templates, discountTemplate } from "../datas/templates";
import FilterSelect from "./FilterSelect";

function QuoteTemplates({ deal, company, salesOwner }) {
  const [offerFilter, setOfferFilter] = useState("");
  const [frequencyFilter, setFrequencyFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [fixtureFilter, setFixtureFilter] = useState("");
  const [quantityFilter, setQuantityFilter] = useState("");
  const [currencyFilter, setCurrencyFilter] = useState("");
  const [langFilter, setLangFilter] = useState("");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  function onClick(template) {
    //Update Hubspot with lines
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        data: template.lines,
        dealId: deal.hs_object_id,
        comment: template.comment,
      }),
    };

    fetch(process.env.REACT_APP_API_URL + "/createLineItemsBatch", requestOptions)
      .then((response) => response.json())
      .then((json) => {
        //Get LineItems du deal
        const lineItems = json.lines;
        //Si la quantité est > 4 : Calculer la discount spéciale
        if (template.quantity > 4) {
          const totalAmount = lineItems
            .map((line) => Number(line.properties.amount))
            .reduce(
              (previousValue, currentValue) => previousValue + currentValue
            );
          const discountAmount = 0.1 * totalAmount;
          //Créer la ligne de produit discount
          const discount = discountTemplate(
            template.currency,
            template.language,
            discountAmount
          );
          const requestDiscountOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              data: discount.lines,
              dealId: deal.hs_object_id,
              comment: template.comment,
            }),
          };
          fetch(process.env.REACT_APP_API_URL + "/createLineItemsBatch", requestDiscountOptions)
            .then((response) => response.json())
            .then((json) => {
              window.location.reload(false);
            });
        } else {
          window.location.reload(false);
        }

        //Updater Amount de la transaction

        //window.location.reload(false);
      });
  }

  function onChange(e) {
    switch (e.target.name) {
      case "offerFilter":
        setOfferFilter(e.target.value);
        break;
      case "frequencyFilter":
        setFrequencyFilter(e.target.value);
        break;
      case "typeFilter":
        setTypeFilter(e.target.value);
        break;
      case "fixtureFilter":
        setFixtureFilter(e.target.value);
        break;
      case "quantityFilter":
        setQuantityFilter(e.target.value);
        break;
      case "currencyFilter":
        setCurrencyFilter(e.target.value);
        break;
      case "langFilter":
        setLangFilter(e.target.value);
        break;

      default:
        break;
    }
  }

  let templatesArray = [];

  const currencies = ["EUR", "USD", "GBP", "AUS"];
  const languages = ["en", "fr"];
  const quantities = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  quantities.forEach((quantity) => {
    currencies.forEach((currency) => {
      languages.forEach((lang) => {
        templates(quantity, currency, lang).forEach((template) => {
          templatesArray.push(template);
        });
      });
    });
  });

  let rows = [];

  if (offerFilter !== "") {
    templatesArray = templatesArray.filter(
      (template) => template.offer.toLowerCase() === offerFilter.toLowerCase()
    );
  }
  if (frequencyFilter !== "") {
    templatesArray = templatesArray.filter(
      (template) =>
        template.frequency.toLowerCase() === frequencyFilter.toLowerCase()
    );
  }
  if (typeFilter !== "") {
    templatesArray = templatesArray.filter(
      (template) => template.type.toLowerCase() === typeFilter.toLowerCase()
    );
  }
  if (fixtureFilter !== "") {
    templatesArray = templatesArray.filter(
      (template) =>
        template.fixture.toLowerCase() === fixtureFilter.toLowerCase()
    );
  }
  if (quantityFilter !== "") {
    templatesArray = templatesArray.filter(
      (template) => template.quantity === quantityFilter
    );
  }
  if (currencyFilter !== "") {
    templatesArray = templatesArray.filter(
      (template) =>
        template.currency.toLowerCase() === currencyFilter.toLowerCase()
    );
  }
  if (langFilter !== "") {
    templatesArray = templatesArray.filter(
      (template) => template.language.toLowerCase() === langFilter.toLowerCase()
    );
  }

  templatesArray.forEach((template) => {
    rows.push(
      <ButtonTemplate
        dealtype={deal.dealtype}
        name={`${template.quantity}_${template.offer}_${template.frequency}_${template.type}_${template.fixture}_${template.currency}_${template.language}`}
        key={`${template.quantity}_${template.offer}_${template.frequency}_${template.type}_${template.fixture}_${template.currency}_${template.language}`}
        template={template}
        onClick={() => onClick(template)}
      ></ButtonTemplate>
    );
  });

  //Else -> Forms
  return (
    <div className="page">
      <div className="quote-page-container valid">
        <div className="original-quote-template">
          <div className="brofile brofile-sticky">
            <div
              wrap="wrap"
              direction="row"
              className="UIFlex__StyledFlex-gi5hfq-0 jAMQbh private-flex Brofile__ResponsiveFlex-sc-1vi4x4x-0 fFinRi brofile-content fixed-width"
            >
              <img
                alt=""
                className="private-image company-logo m-y-auto img-responsive private-image--responsive"
                src="https://cdn2.hubspot.net/hubfs/4217512/Noir-fd%20transparent-signature.png"
              ></img>
              <div className="text-center m-y-auto">
                <ul className="uiList private-list--inline quote-actions private-list--unstyled">
                  <li className="uiListItem private-list__item">
                    <button className="flag-button">
                      <img
                        src="https://img.icons8.com/color/96/000000/great-britain-circular.png"
                        alt="GB"
                        width="48"
                        height="48"
                        onClick={() => changeLanguage("en")}
                      />
                    </button>
                  </li>
                  <li className="uiListItem private-list__item">
                    <button className="flag-button">
                      <img
                        src="https://img.icons8.com/color/96/000000/france-circular.png"
                        width="48"
                        height="48"
                        alt="FR"
                        onClick={() => changeLanguage("fr")}
                      />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Topbar
            deal={deal}
            company={company}
            salesOwner={salesOwner}
          ></Topbar>
          <div className="quote-terms-container fixed-width">
            <h2>Filtres</h2>
            <div className="hs-form__row">
              <div className="hs-form__group">
                <div className="hs-form__field-row">
                  <div className="hs-form__field-row__column">
                    <FilterSelect
                      name="offerFilter"
                      label="Offer"
                      value={offerFilter}
                      onChange={(e) => onChange(e)}
                      options={["Starter", "Pro"]}
                    ></FilterSelect>
                  </div>
                  <div className="hs-form__field-row__column">
                    <FilterSelect
                      name="frequencyFilter"
                      label="Frequency"
                      value={frequencyFilter}
                      onChange={(e) => onChange(e)}
                      options={["Monthly", "Annually"]}
                    ></FilterSelect>
                  </div>
                  <div className="hs-form__field-row__column">
                    <FilterSelect
                      name="typeFilter"
                      label="Type"
                      value={typeFilter}
                      onChange={(e) => onChange(e)}
                      options={["Flat", "Vet", "Trot"]}
                    ></FilterSelect>
                  </div>
                  <div className="hs-form__field-row__column">
                    <FilterSelect
                      name="fixtureFilter"
                      label="Fixture"
                      value={fixtureFilter}
                      onChange={(e) => onChange(e)}
                      options={["Girth", "Pad-Sport", "Pad-Racing", "Trot"]}
                    ></FilterSelect>
                  </div>
                  <div className="hs-form__field-row__column">
                    <FilterSelect
                      name="quantityFilter"
                      label="Quantity"
                      value={quantityFilter}
                      onChange={(e) => onChange(e)}
                      options={quantities}
                    ></FilterSelect>
                  </div>
                  <div className="hs-form__field-row__column">
                    <FilterSelect
                      name="currencyFilter"
                      label="Currency"
                      value={currencyFilter}
                      onChange={(e) => onChange(e)}
                      options={currencies}
                    ></FilterSelect>
                  </div>
                  <div className="hs-form__field-row__column">
                    <FilterSelect
                      name="langFilter"
                      label="Language"
                      value={langFilter}
                      onChange={(e) => onChange(e)}
                      options={languages}
                    ></FilterSelect>
                  </div>
                </div>
              </div>
            </div>
            <div>{rows}</div>
          </div>

          <Footer deal={deal} salesOwner={salesOwner}></Footer>
        </div>
      </div>
    </div>
  );
}

export default QuoteTemplates;
