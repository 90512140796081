//COMPONENTS
import PriceSummary from "./PriceSummary";

function LineItemwRow({ lineItem }) {
  return (
    <div className="media private-media private-media--spaced-xl quote-line-item m-top-5 p-bottom-2">
      <div className="media-body private-media__body">
        <div
          data-test-id="original-quote-line-item"
          className="quote-line-item-details"
        >
          <h5 className="m-bottom-2">{lineItem.name}</h5>
          <pre className="m-top-2">{lineItem.description}</pre>
        </div>
      </div>
      <div className="private-media__item private-media__item--right">
        <PriceSummary lineItem={lineItem}> </PriceSummary>
      </div>
    </div>
  );
}

export default LineItemwRow;
