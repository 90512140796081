//CSS
import "../styles/UserForms.css";

function TextField({
  name,
  label,
  required,
  value,
  onChange,
  errorMessage,
  disabled,
  placeholder,
}) {
  return (
    <div className="hs-form__field">
      <label
        id={name + "-label"}
        htmlFor={name + "-input"}
        className={
          required === "true"
            ? "hs-form__field__label hs-form__field__label_required"
            : "hs-form__field__label"
        }
        data-required={required}
      >
        <span>{label}</span>
      </label>
      <input
        id={name + "-input"}
        aria-labelledby={name + "-label"}
        className="hs-form__field__input "
        type="string"
        name={name}
        required=""
        inputMode="text"
        aria-invalid="false"
        aria-required={required}
        data-field-type={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
      ></input>
      {errorMessage && (
        <div
          id="email__error"
          class="hs-form__field__alert hs-form__field__alert_error"
        >
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
}

export default TextField;
