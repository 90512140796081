import React from "react";

function ButtonTemplate({ dealtype, name, template, onClick }) {
  var buttonClassname = "button-checkout-equimetre";

  switch (dealtype) {
    case "EQUIMETRE FLAT":
      buttonClassname = "button-checkout-equimetre";
      break;
    case "EQUIMETRE TROT":
      buttonClassname = "button-checkout-equimetre";
      break;
    case "EQUIMETRE VET":
      buttonClassname = "button-checkout-equimetre-vet";
      break;
    case "EQUISYM":
      buttonClassname = "button-checkout-equisym";
      break;
    default:
      break;
  }

  return (
    <div className="fixed-width">
      <div className="text-center m-top-6 m-bottom-6">
        <button
          id={name + "-input"}
          type="button"
          data-selenium-test="quotes-purchase-btn"
          data-loading="false"
          data-loading-state="idle"
          aria-disabled="false"
          className={
            "uiButton private-button private-button--default private-loading-button custom-color-button private-button--active private-button--non-link " +
            buttonClassname
          }
          data-button-use="tertiary"
          aria-pressed="true"
          onClick={onClick}
          name={name}
        >
          <span
            className="private-loading-button__content private-button--internal-spacing"
            name={name}
          >
            <div>
              <p>
                {template.quantity} x EQM {template.offer.toUpperCase()}{" "}
                {template.type.toUpperCase()} {template.frequency.toUpperCase()}{" "}
                for {template.fixture.toUpperCase()}{" "}
              </p>
              <p>
                (Currency: {template.currency} / Lang: {template.language})
              </p>
            </div>
          </span>
        </button>
      </div>
    </div>
  );
}

export default ButtonTemplate;
