//COMPONENTS
import Trans from "./Trans.js";
//UTILS
import { textFrequencyFromHs, getCurrencyText } from "../utilities/Utils.js";

function PriceSummary({ lineItem }) {
  //DISCOUNT AMOUNT
  if (lineItem.discount !== null) {
    return (
      <div className="quote-line-item-price-summary">
        <p className="m-bottom-0">
          <span>{lineItem.quantity} x </span>
          <span className="is--text--help strikethrough">
            <span
              data-locale-at-render="en"
              data-key="quotes.recurrence.annually"
            >
              <span>
                <span>
                  {getCurrencyText(
                    lineItem.price,
                    lineItem.hs_line_item_currency_code
                  )}
                </span>
              </span>
              <span>
                {" "}
                {textFrequencyFromHs(lineItem.recurringbillingfrequency)}
              </span>
            </span>
          </span>
        </p>
        <p className="m-bottom-0">
          <span className="is--text--success">
            <span data-locale-at-render="en" data-key="quotes.discountValue">
              <span>
                <span>
                  {getCurrencyText(
                    lineItem.discount,
                    lineItem.hs_line_item_currency_code
                  )}
                </span>
              </span>
              <span>
                {" "}
                <Trans text="price_summary_discount"></Trans>
              </span>
            </span>
          </span>
          <strong className="m-left-3">
            <span
              data-locale-at-render="en"
              data-key="quotes.recurrence.annually"
            >
              <span>
                <span>
                  {getCurrencyText(
                    lineItem.amount,
                    lineItem.hs_line_item_currency_code
                  )}
                </span>
              </span>
              <span>
                {" "}
                {textFrequencyFromHs(lineItem.recurringbillingfrequency)}
              </span>
            </span>
          </strong>
        </p>
      </div>
    );
  } else if (lineItem.hs_discount_percentage !== null) {
    return (
      <div className="quote-line-item-price-summary">
        <p className="m-bottom-0">
          <span>{lineItem.quantity} x </span>
          <span className="is--text--help strikethrough">
            <span
              data-locale-at-render="en"
              data-key="quotes.recurrence.annually"
            >
              <span>
                <span>
                  {getCurrencyText(
                    lineItem.price,
                    lineItem.hs_line_item_currency_code
                  )}
                </span>
              </span>
              <span>
                {" "}
                {textFrequencyFromHs(lineItem.recurringbillingfrequency)}
              </span>
            </span>
          </span>
        </p>
        <p className="m-bottom-0">
          <span className="is--text--success">
            <span data-locale-at-render="en" data-key="quotes.discountValue">
              <span>
                <span>{lineItem.hs_discount_percentage}%</span>
              </span>
              <span>
                {" "}
                <Trans text="price_summary_discount"></Trans>
              </span>
            </span>
          </span>
          <strong className="m-left-3">
            <span
              data-locale-at-render="en"
              data-key="quotes.recurrence.annually"
            >
              <span>
                <span>
                  {getCurrencyText(
                    lineItem.amount,
                    lineItem.hs_line_item_currency_code
                  )}
                </span>
              </span>
              <span>
                {" "}
                {textFrequencyFromHs(lineItem.recurringbillingfrequency)}
              </span>
            </span>
          </strong>
        </p>
      </div>
    );
  }
  //NO DISCOUNT
  else {
    return (
      <div className="quote-line-item-price-summary">
        <p className="m-bottom-0">
          <span>{lineItem.quantity} x </span>
          <span>
            <span>
              <span>
                {getCurrencyText(
                  lineItem.amount / lineItem.quantity,
                  lineItem.hs_line_item_currency_code
                )}
              </span>
            </span>
            <span>
              {" "}
              {textFrequencyFromHs(lineItem.recurringbillingfrequency)}
            </span>
          </span>
        </p>
        <p className="m-bottom-0">
          <strong className="m-left-3">
            <span
              data-locale-at-render="en"
              data-key="quotes.recurrence.annually"
            >
              <span>
                <span>
                  {getCurrencyText(
                    lineItem.amount,
                    lineItem.hs_line_item_currency_code
                  )}
                </span>
              </span>
              <span>
                {" "}
                {textFrequencyFromHs(lineItem.recurringbillingfrequency)}
              </span>
            </span>
          </strong>
        </p>
      </div>
    );
  }
}

export default PriceSummary;
