//CSS
import "../styles/UserForms.css";

function FilterSelect({ name, label, value, onChange, options }) {
  const optionsArray = [];
  options.forEach((option) => {
    optionsArray.push(
      <option label={option} value={option}>
        {option}
      </option>
    );
  });
  return (
    <div className="hs-form__field">
      <label
        id={name + "-label"}
        htmlFor={name + "-input"}
        className="hs-form__field__label hs-form__field__label_required"
        data-required={true}
      >
        <span>{label}</span>
      </label>
      <select
        id={name + "-input"}
        aria-labelledby={name + "-label"}
        className="hs-form__field__input"
        name={name}
        aria-invalid="false"
        aria-required={true}
        value={value}
        onChange={onChange}
      >
        <option label="-" value="">
          All
        </option>
        {optionsArray}
      </select>
    </div>
  );
}

export default FilterSelect;
