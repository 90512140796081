//COMPONENTS
import Trans from "./Trans.js";

function QuoteExpired() {
  return (
    <div className="quote-line-items-and-services m-y-10">
      <div className="fixed-width">
        <h1 className="text-center m-bottom-4">
          <Trans text="quote_expired"></Trans>
        </h1>
      </div>
    </div>
  );
}

export default QuoteExpired;
