//COMPONENTS
import Trans from "./Trans.js";

function PurchaseTermsText({ dealtype }) {
    var quoteTermsClassname = "rounded-border quote-terms";
    var quoteTermsLink = "https://hubs.ly/H0n42zC0";

    switch (dealtype) {
        case "EQUIMETRE FLAT":
            quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre";
            break;
        case "EQUIMETRE TROT":
            quoteTermsClassname = "rounded-border quote-terms quote-terms-equimetre";
            break;
        case "EQUIMETRE VET":
            quoteTermsClassname =
                "rounded-border quote-terms quote-terms-equimetre-vet";
            break;
        case "EQUISYM":
            quoteTermsClassname = "rounded-border quote-terms quote-terms-equisym";
            quoteTermsLink = "https://hubs.ly/Q01l4qmf0"
            break;
        default:
            break;
    }

    return (
        <div className="quote-terms-and-signature-container">
            <div
                className="quote-terms-container fixed-width"
                data-selenium-test="quotes-terms-container"
            >
                <div className={quoteTermsClassname}>
                    <h5 className="m-bottom-4 text-center">
                        <Trans text="purchase_terms_title"></Trans>
                    </h5>
                    <div className="m-bottom-0 is--heading-7 quote-rich-text-body link-obsidian-container">
                        <p>
                            <strong>
                                <Trans text="purchase_terms_terms_of_payment"></Trans>
                            </strong>{" "}
                : <Trans text="purchase_terms_line1"></Trans>
                        </p>
                        <p>
                            <Trans text="purchase_terms_line2"></Trans>
                        </p>
                        <p></p>
                        <p>
                            <Trans text="purchase_terms_line3"></Trans>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PurchaseTermsText;
