//COMPONENTS
import LineItemsRow from "./LineItemsRow.js";

function LineItemsTable({ lineItems }) {
  let lineItemsAsArray = Object.keys(lineItems)
    .map((pid) => lineItems[pid])
    .sort((a, b) => Number(b.amount) - Number(a.amount));
  let rows = [];

  lineItemsAsArray.forEach((lineItem) => {
    rows.push(
      <LineItemsRow
        key={lineItem.hs_object_id}
        lineItem={lineItem}
      ></LineItemsRow>
    );
  });

  return <div>{rows}</div>;
}

export default LineItemsTable;
