//CSS
import "../styles/UserForms.css";
//COMPONENTS
import Trans from "./Trans.js";

function PaymentMethode({ values, lineItems, onChange, errorMessage }) {
  const bankTransfertDiv = (
    <div>
      <div className="hs-form__field__radio__container">
        <label
          id="payment_method-cca5d599-556a-44ac-9fc4-b512c99e62f6-label"
          className="hs-form__field__label  hs-form__field__checkbox-label hs-form__field__radio__label"
        >
          <input
            id="payment_method-cca5d599-556a-44ac-9fc4-b512c99e62f6-input"
            aria-labelledby="payment_method-label payment_method-cca5d599-556a-44ac-9fc4-b512c99e62f6-label"
            className="hs-form__field__input  hs-form__field__radio__input hs-form__field__radio__input--radio"
            type="radio"
            name="paymentMethod"
            value="Bank Transfer"
            onChange={(e) => onChange(e)}
          ></input>
          <span className="hs-form__field__radio__label-span">
            <Trans text="payment_method_bank_transfer"></Trans>
          </span>
        </label>
      </div>
    </div>
  );

  const lines = lineItems.filter((lineItem) => {
    return lineItem.recurringbillingfrequency !== null;
  });
  const recurringBillingFrequency =
    lines.length > 0 ? lines[0].recurringbillingfrequency : "One-shot";

  return (
    <div className="fixed-width">
      <h1 className="text-center m-bottom-4">
        <Trans text="payment_method_title"></Trans>
      </h1>
      <div className="hs-form__row">
        <div className="hs-form__group">
          <div className="hs-form__field-row">
            <div className="hs-form__field-row__column">
              <div className="hs-form__field">
                <label
                  id="payment_method-label"
                  htmlFor="payment_method-input"
                  className="hs-form__field__label hs-form__field__label_required"
                  data-required="true"
                >
                  <span>
                    <Trans text="payment_method_subtitle"></Trans> :
                  </span>
                </label>
                <div className="col-xs-6">
                  <div
                    className="hs-form__field__options__container"
                    role="radiogroup"
                    aria-labelledby="payment_method-label"
                    aria-required="true"
                  >
                    <div>
                      <div className="hs-form__field__radio__container">
                        <label
                          id="payment_method-cca5d599-556a-44ac-9fc4-b512c99e62f6-label"
                          className="hs-form__field__label  hs-form__field__checkbox-label hs-form__field__radio__label"
                        >
                          <input
                            id="payment_method-cca5d599-556a-44ac-9fc4-b512c99e62f6-input"
                            aria-labelledby="payment_method-label payment_method-cca5d599-556a-44ac-9fc4-b512c99e62f6-label"
                            className="hs-form__field__input  hs-form__field__radio__input hs-form__field__radio__input--radio"
                            type="radio"
                            name="paymentMethod"
                            value="Stripe / Credit Card"
                            onChange={(e) => onChange(e)}
                          ></input>
                          <span className="hs-form__field__radio__label-span">
                            <Trans text="payment_method_credit_card"></Trans>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="hs-form__field__radio__container">
                        <label
                          id="payment_method-cca5d599-556a-44ac-9fc4-b512c99e62f6-label"
                          className="hs-form__field__label  hs-form__field__checkbox-label hs-form__field__radio__label"
                        >
                          <input
                            id="payment_method-cca5d599-556a-44ac-9fc4-b512c99e62f6-input"
                            aria-labelledby="payment_method-label payment_method-cca5d599-556a-44ac-9fc4-b512c99e62f6-label"
                            className="hs-form__field__input  hs-form__field__radio__input hs-form__field__radio__input--radio"
                            type="radio"
                            name="paymentMethod"
                            value="SEPA (Europe only)"
                            onChange={(e) => onChange(e)}
                          ></input>
                          <span className="hs-form__field__radio__label-span">
                            <Trans text="payment_method_sepa"></Trans>
                          </span>
                        </label>
                      </div>
                    </div>
                    {lines &&
                      recurringBillingFrequency !== "monthly" &&
                      bankTransfertDiv}
                  </div>
                </div>
                {errorMessage && (
                  <div
                    id="email__error"
                    className="hs-form__field__alert hs-form__field__alert_error"
                  >
                    <span>{errorMessage}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentMethode;
