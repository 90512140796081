const eqmModels = {
  starter: {
    annually: {
      flat: {
        girth: {
          fr: {
            name: "EQUIMETRE STARTER (EL)",
            hs_product_id: "69630063",
          },
          en: {
            name: "EQUIMETRE STARTER (EL)",
            hs_product_id: "87031853",
          },
        },
        pad: {
          fr: {
            name: "817733268",
            hs_product_id: "EQUIMETRE STARTER (CO)",
          },
          en: {
            name: "817732738",
            hs_product_id: "EQUIMETRE STARTER (CO)",
          },
        },
      },
      vet: {
        girth: {
          fr: {
            name: "EQUIMETRE STARTER (EL)",
            hs_product_id: "69630063",
          },
          en: {
            name: "EQUIMETRE STARTER (EL)",
            hs_product_id: "87031853",
          },
        },
        pad: {
          fr: {
            name: "EQUIMETRE STARTER (CO)",
            hs_product_id: "817733268",
          },
          en: {
            name: "EQUIMETRE STARTER (CO)",
            hs_product_id: "817732738",
          },
        },
      },
      trot: {
        trot: {
          fr: {
            name: "EQUIMETRE STARTER (CO)",
            hs_product_id: "817733268",
          },
          en: {
            name: "EQUIMETRE STARTER (CO)",
            hs_product_id: "817732738",
          },
        },
      },
    },
  },
  pro: {
    monthly: {
      flat: {
        girth: {
          fr: {
            name: "EQUIMETRE PRO (EL.M)",
            hs_product_id: "66552792",
          },
          en: {
            name: "EQUIMETRE PRO (EL.M)",
            hs_product_id: "87032130",
          },
        },
        pad: {
          fr: {
            name: "EQUIMETRE PRO (CO.M)",
            hs_product_id: "817464154",
          },
          en: {
            name: "EQUIMETRE PRO (CO.M)",
            hs_product_id: "817815142",
          },
        },
      },
      vet: {
        girth: {
          fr: {
            name: "EQUIMETRE VET PRO (EL.M)",
            hs_product_id: "817564728",
          },
          en: {
            name: "EQUIMETRE VET PRO (EL.M)",
            hs_product_id: "817648218",
          },
        },
        pad: {
          fr: {
            name: "EQUIMETRE VET PRO (CO.M)",
            hs_product_id: "147234361",
          },
          en: {
            name: "EQUIMETRE VET PRO (CO.M)",
            hs_product_id: "147234369",
          },
        },
      },
      trot: {
        trot: {
          fr: {
            name: "EQUIMETRE PRO (CO.M)",
            hs_product_id: "817464154",
          },
          en: {
            name: "EQUIMETRE PRO (CO.M)",
            hs_product_id: "817815142",
          },
        },
      },
    },
    annually: {
      flat: {
        girth: {
          fr: {
            name: "EQUIMETRE PRO (EL.A)",
            hs_product_id: "69742828",
          },
          en: {
            name: "EQUIMETRE PRO (EL.A)",
            hs_product_id: "69742828",
          },
        },
        pad: {
          fr: {
            name: "EQUIMETRE PRO (CO.A)",
            hs_product_id: "817903227",
          },
          en: {
            name: "EQUIMETRE PRO (CO.A)",
            hs_product_id: "817903438",
          },
        },
      },
      vet: {
        girth: {
          fr: {
            name: "EQUIMETRE VET PRO (EL.A)",
            hs_product_id: "817816091",
          },
          en: {
            name: "EQUIMETRE VET PRO (EL.A)",
            hs_product_id: "817552743",
          },
        },
        pad: {
          fr: {
            name: "EQUIMETRE VET PRO (CO.A)",
            hs_product_id: "728311650",
          },
          en: {
            name: "EQUIMETRE VET PRO (CO.A)",
            hs_product_id: "728311648",
          },
        },
      },
      trot: {
        trot: {
          fr: {
            name: "EQUIMETRE PRO (CO.A)",
            hs_product_id: "817903227",
          },
          en: {
            name: "EQUIMETRE PRO (CO.A)",
            hs_product_id: "817903438",
          },
        },
      },
    },
  },
};

const girthModels = {
  vet: {
    fr: {
      name: "SANGLE EQUIMETRE",
      hs_product_id: "817815410",
    },
    en: {
      name: "ELECTRODE SURCINGLE",
      hs_product_id: "817548565",
    },
  },
  trot: {
    fr: {
      name: "SANGLE EQUIMETRE TROT'",
      hs_product_id: "817867059",
    },
    en: {
      name: "HARNESS SURCINGLE",
      hs_product_id: "817817392",
    },
  },
};

const padModels = {
  racing: {
    fr: {
      name: "TAPIS EQUIMETRE - COURSE",
      hs_product_id: "817817882",
    },
    en: {
      name: "EQUIMETRE PAD - RACING PUFFER",
      hs_product_id: "817716620",
    },
  },
  sport: {
    fr: {
      name: "TAPIS EQUIMETRE - SPORT",
      hs_product_id: "817833483",
    },
    en: {
      name: "EQUIMETRE PAD - SPORTS SADDLE",
      hs_product_id: "817550238",
    },
  },
};

const commentModel = {
  pro_monthly: {
    fr: "",
    en:
      "With the all-inclusive Pro Subscription, you rent the device with no start up costs, unlimited support, maintenance & warranty. You also benefit of :\n - A dedicated account representative for personalized assistance, pre- racing reports and data coaching to better understand and navigate through the data,\n - The Analytics tab on the platform for utmost data analysis and download,\n - The latest device on the market upon renewal if there's a new generation. \n*Min. 10 month subscription. \nLooking forward having you in the Arioneo clan.",
  },
  pro_annually_less_than_5: {
    fr: "",
    en:
      "With the all-inclusive Pro Subscription, you rent the device with no start up costs, unlimited support, maintenance & warranty. You also benefit of :\n - A dedicated account representative for personalized assistance, pre- racing reports and data coaching to better understand and navigate through the data,\n - The Analytics tab on the platform for utmost data analysis and download,\n - The latest device on the market upon renewal if there's a new generation. \n\nLooking forward having you in the Arioneo clan.",
  },
  pro_annually_more_than_4: {
    fr: "",
    en:
      "With the all-inclusive Pro Subscription, you rent the device with no start up costs, unlimited support, maintenance & warranty. You also benefit of :\n - A dedicated account representative for personalized assistance, pre- racing reports and data coaching to better understand and navigate through the data,\n - The Analytics tab on the platform for utmost data analysis and download,\n - The latest device on the market upon renewal if there's a new generation.\n\nNote the Pro Partner Discount is applicable & renewable for orders of 5 devices or more under the condition of mentions on social network posts, 2 interviews per year, the use of image and quotes on our social networks (all sharing and content being subject to prior validation by the trainer). \n\nLooking forward having you in the Arioneo clan.",
  },
};

const discountModel = {
  pro_annually_more_than_4: {
    fr: {
      name: "REMISE SERVICE PRO PARTENAIRE COMMUNICATION",
      hs_product_id: "1314140987",
    },
    en: {
      name: "PRO PARTNER COMMUNICATION DISCOUNT",
      hs_product_id: "1314141003",
    },
  },
};

export function discountTemplate(currency, language, amount) {
  return {
    lines: [
      {
        properties: {
          name: discountModel.pro_annually_more_than_4[language].name,
          hs_product_id:
            discountModel.pro_annually_more_than_4[language].hs_product_id,
          quantity: "1",
          hs_line_item_currency_code: currency,
          discount: amount,
        },
      },
    ],
  };
}

function percentageDiscount(quantity) {
  switch (quantity) {
    case 1:
      return "8";
    case 2:
      return "12";
    case 3:
      return "16";
    default:
      return "20";
  }
}

export function templates(quantity, currency, language) {
  return [
    //STARTER-FLAT-GIRTH
    {
      offer: "starter",
      frequency: "annually",
      type: "flat",
      fixture: "girth",
      quantity: quantity,
      currency: currency,
      language: language,
      lines: [
        {
          properties: {
            name: eqmModels.starter.annually.flat.girth[language].name,
            hs_product_id:
              eqmModels.starter.annually.flat.girth[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //STARTER-FLAT-PAD-RACING
    {
      offer: "starter",
      frequency: "annually",
      type: "flat",
      fixture: "pad-racing",
      quantity: quantity,
      currency: currency,
      language: language,
      lines: [
        {
          properties: {
            name: eqmModels.starter.annually.flat.pad[language].name,
            hs_product_id:
              eqmModels.starter.annually.flat.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.racing[language].name,
            hs_product_id: padModels.racing[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //STARTER-FLAT-PAD-SPORT
    {
      offer: "starter",
      frequency: "annually",
      type: "flat",
      fixture: "pad-sport",
      quantity: quantity,
      currency: currency,
      language: language,
      lines: [
        {
          properties: {
            name: eqmModels.starter.annually.flat.pad[language].name,
            hs_product_id:
              eqmModels.starter.annually.flat.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.sport[language].name,
            hs_product_id: padModels.sport[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //STARTER-TROT-TROT
    {
      frequency: "annually",
      offer: "starter",
      type: "trot",
      fixture: "trot",
      quantity: quantity,
      currency: currency,
      language: language,
      lines: [
        {
          properties: {
            name: eqmModels.starter.annually.trot.trot[language].name,
            hs_product_id:
              eqmModels.starter.annually.trot.trot[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: girthModels.trot[language].name,
            hs_product_id: girthModels.trot[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
            hs_discount_percentage: "100",
          },
        },
      ],
    },
    //STARTER-VET-GIRTH
    {
      offer: "starter",
      frequency: "annually",
      type: "vet",
      fixture: "girth",
      quantity: quantity,
      currency: currency,
      language: language,
      lines: [
        {
          properties: {
            name: eqmModels.starter.annually.vet.girth[language].name,
            hs_product_id:
              eqmModels.starter.annually.vet.girth[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //STARTER-VET-PAD-RACING
    {
      offer: "starter",
      frequency: "annually",
      type: "vet",
      fixture: "pad-racing",
      quantity: quantity,
      currency: currency,
      language: language,
      lines: [
        {
          properties: {
            name: eqmModels.starter.annually.vet.pad[language].name,
            hs_product_id:
              eqmModels.starter.annually.vet.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.racing[language].name,
            hs_product_id: padModels.racing[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //STARTER-VET-PAD-SPORT
    {
      offer: "starter",
      frequency: "annually",
      type: "vet",
      fixture: "pad-sport",
      quantity: quantity,
      currency: currency,
      language: language,
      lines: [
        {
          properties: {
            name: eqmModels.starter.annually.vet.pad[language].name,
            hs_product_id:
              eqmModels.starter.annually.vet.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.sport[language].name,
            hs_product_id: padModels.sport[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-MONTHLY-FLAT-GIRTH
    {
      offer: "pro",
      frequency: "monthly",
      type: "flat",
      fixture: "girth",
      quantity: quantity,
      currency: currency,
      language: language,
      comment: commentModel.pro_monthly[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.monthly.flat.girth[language].name,
            hs_product_id:
              eqmModels.pro.monthly.flat.girth[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-MONTHLY-FLAT-PAD-RACING
    {
      offer: "pro",
      frequency: "monthly",
      type: "flat",
      fixture: "pad-racing",
      quantity: quantity,
      currency: currency,
      language: language,
      comment: commentModel.pro_monthly[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.monthly.flat.pad[language].name,
            hs_product_id:
              eqmModels.pro.monthly.flat.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.racing[language].name,
            hs_product_id: padModels.racing[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-MONTHLY-FLAT-PAD-SPORT
    {
      offer: "pro",
      frequency: "monthly",
      type: "flat",
      fixture: "pad-sport",
      quantity: quantity,
      currency: currency,
      language: language,
      comment: commentModel.pro_monthly[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.monthly.flat.pad[language].name,
            hs_product_id:
              eqmModels.pro.monthly.flat.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.sport[language].name,
            hs_product_id: padModels.sport[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-MONTHLY-VET-GIRTH
    {
      offer: "pro",
      frequency: "monthly",
      type: "vet",
      fixture: "girth",
      quantity: quantity,
      currency: currency,
      language: language,
      comment: commentModel.pro_monthly[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.monthly.vet.girth[language].name,
            hs_product_id:
              eqmModels.pro.monthly.vet.girth[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-MONTHLY-VET-PAD-RACING
    {
      offer: "pro",
      frequency: "monthly",
      type: "vet",
      fixture: "pad-racing",
      quantity: quantity,
      currency: currency,
      language: language,
      comment: commentModel.pro_monthly[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.monthly.vet.pad[language].name,
            hs_product_id:
              eqmModels.pro.monthly.vet.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.racing[language].name,
            hs_product_id: padModels.racing[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-MONTHLY-VET-PAD-SPORT
    {
      offer: "pro",
      frequency: "monthly",
      type: "vet",
      fixture: "pad-sport",
      quantity: quantity,
      currency: currency,
      language: language,
      comment: commentModel.pro_monthly[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.monthly.vet.pad[language].name,
            hs_product_id:
              eqmModels.pro.monthly.vet.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.sport[language].name,
            hs_product_id: padModels.sport[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-MONTHLY-TROT-TROT
    {
      offer: "pro",
      frequency: "monthly",
      type: "trot",
      fixture: "trot",
      quantity: quantity,
      currency: currency,
      language: language,
      comment: commentModel.pro_monthly[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.monthly.trot.trot[language].name,
            hs_product_id:
              eqmModels.pro.monthly.trot.trot[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: girthModels.trot[language].name,
            hs_product_id: girthModels.trot[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-ANNUALY-FLAT-GIRTH
    {
      offer: "pro",
      frequency: "annually",
      type: "flat",
      fixture: "girth",
      quantity: quantity,
      currency: currency,
      language: language,
      comment:
        quantity > 4
          ? commentModel.pro_annually_more_than_4[language]
          : commentModel.pro_annually_less_than_5[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.annually.flat.girth[language].name,
            hs_product_id:
              eqmModels.pro.annually.flat.girth[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
            hs_discount_percentage: percentageDiscount(quantity),
          },
        },
      ],
    },
    //PRO-ANNUALY-FLAT-PAD-RACING
    {
      offer: "pro",
      frequency: "annually",
      type: "flat",
      fixture: "pad-racing",
      quantity: quantity,
      currency: currency,
      language: language,
      comment:
        quantity > 4
          ? commentModel.pro_annually_more_than_4
          : commentModel.pro_annually_less_than_5,
      lines: [
        {
          properties: {
            name: eqmModels.pro.annually.flat.pad[language].name,
            hs_product_id:
              eqmModels.pro.annually.flat.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
            hs_discount_percentage: percentageDiscount(quantity),
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.racing[language].name,
            hs_product_id: padModels.racing[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-ANNUALY-FLAT-PAD-SPORT
    {
      offer: "pro",
      frequency: "annually",
      type: "flat",
      fixture: "pad-sport",
      quantity: quantity,
      currency: currency,
      language: language,
      comment:
        quantity > 4
          ? commentModel.pro_annually_more_than_4[language]
          : commentModel.pro_annually_less_than_5[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.annually.flat.pad[language].name,
            hs_product_id:
              eqmModels.pro.annually.flat.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
            hs_discount_percentage: percentageDiscount(quantity),
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.sport[language].name,
            hs_product_id: padModels.sport[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-ANNUALY-VET-GIRTH
    {
      offer: "pro",
      frequency: "annually",
      type: "vet",
      fixture: "girth",
      quantity: quantity,
      currency: currency,
      language: language,
      comment:
        quantity > 4
          ? commentModel.pro_annually_more_than_4[language]
          : commentModel.pro_annually_less_than_5[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.annually.vet.girth[language].name,
            hs_product_id:
              eqmModels.pro.annually.vet.girth[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
            hs_discount_percentage: percentageDiscount(quantity),
          },
        },
      ],
    },
    //PRO-ANNUALY-VET-PAD-RACING
    {
      offer: "pro",
      frequency: "annually",
      type: "vet",
      fixture: "pad-racing",
      quantity: quantity,
      currency: currency,
      language: language,
      comment:
        quantity > 4
          ? commentModel.pro_annually_more_than_4[language]
          : commentModel.pro_annually_less_than_5[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.annually.vet.pad[language].name,
            hs_product_id:
              eqmModels.pro.annually.vet.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
            hs_discount_percentage: percentageDiscount(quantity),
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.racing[language].name,
            hs_product_id: padModels.racing[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-ANNUALY-VET-PAD-SPORT
    {
      offer: "pro",
      frequency: "annually",
      type: "vet",
      fixture: "pad-sport",
      quantity: quantity,
      currency: currency,
      language: language,
      comment:
        quantity > 4
          ? commentModel.pro_annually_more_than_4[language]
          : commentModel.pro_annually_less_than_5[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.annually.vet.pad[language].name,
            hs_product_id:
              eqmModels.pro.annually.vet.pad[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
            hs_discount_percentage: percentageDiscount(quantity),
          },
        },
        {
          properties: {
            name: girthModels.vet[language].name,
            hs_product_id: girthModels.vet[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: padModels.sport[language].name,
            hs_product_id: padModels.sport[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
      ],
    },
    //PRO-ANNUALY-TROT-TROT
    {
      offer: "pro",
      frequency: "annually",
      type: "trot",
      fixture: "trot",
      quantity: quantity,
      currency: currency,
      language: language,
      comment:
        quantity > 4
          ? commentModel.pro_annually_more_than_4[language]
          : commentModel.pro_annually_less_than_5[language],
      lines: [
        {
          properties: {
            name: eqmModels.pro.annually.trot.trot[language].name,
            hs_product_id:
              eqmModels.pro.annually.trot.trot[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
          },
        },
        {
          properties: {
            name: girthModels.trot[language].name,
            hs_product_id: girthModels.trot[language].hs_product_id,
            quantity: quantity,
            hs_line_item_currency_code: currency,
            hs_discount_percentage: "100",
          },
        },
      ],
    },
  ];
}
