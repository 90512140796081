export const SALES = {
  61896111: {
    id: 1,
    email: "anne@arioneo.com",
    firstname: "Anne",
    lastname: "Moynet Remond",
    description: "International Sales Manager",
    tel: "+33 6 79 77 95 22",
    avatar:
      "https://app.hubspot.com/hubsettings/v1/avatar/hash/8f6c72297c41bb4248f19f437c9bde25/144",
    cegidCode: "AMY",
  },
  31137735: {
    id: 2,
    email: "valentin@arioneo.com",
    firstname: "Valentin",
    lastname: "Rapin",
    description: "Managing Director",
    tel: "+33 6 86 60 27 12",
    avatar:
      "https://api.hubspot.com/userpreferences/v1/avatar/6bc088eb39d888860e743fd661c47a0d/144",
    cegidCode: "VRA",
  },
  41900540: {
    id: 3,
    email: "franck@arioneo.com",
    firstname: "Franck",
    lastname: "Harrang",
    description: "Représentant Commercial France",
    tel: "+33 6 01 86 61 09",
    avatar:
      "https://api.hubspot.com/userpreferences/v1/avatar/af3e159563451e3b7505ca1785cb9f14/64",
    cegidCode: "FHR",
  },
  111483395: {
    id: 4,
    email: "charlotte@arioneo.com",
    firstname: "Charlotte",
    lastname: "de Soultrait",
    description: "Data Success Manager",
    tel: "+33 7 61 70 21 91",
    avatar:
      "https://api.hubspot.com/userpreferences/v1/avatar/41254a4faf5421850c1152c4af484efb/144",
    cegidCode: "CDS",
  },
  40214378: {
    id: 5,
    email: "coline@arioneo.com",
    firstname: "Coline",
    lastname: "Labadie",
    description: "Data Success Manager",
    tel: "+33 6 52 64 87 79",
    avatar:
      "https://api.hubspot.com/userpreferences/v1/avatar/3bed5b68f6eb41924ef85069f8665068/64",
    cegidCode: "CLB",
  },
  31740797: {
    id: 6,
    email: "erwan@arioneo.com",
    firstname: "Erwan",
    lastname: "Mellerio",
    description: "Project Manager",
    tel: "+33 6 87 17 16 23",
    avatar:
      "https://api.hubspot.com/userpreferences/v1/avatar/ea0f394ce27d782fe5f525a0d607e37e/64",
    cegidCode: "EME",
  },
  62910380: {
    id: 6,
    email: "helene@arioneo.com",
    firstname: "Hélène",
    lastname: "Papon Perrier",
    description: "Data Success Manager",
    tel: "+33 7 67 09 42 56",
    avatar:
      "https://api.hubspot.com/userpreferences/v1/avatar/a9ff4cbfa9721d1ff704c4b8d9e17909/64",
    cegidCode: "HPA",
  },
  79154089: {
    id: 6,
    email: "theo@arioneo.com",
    firstname: "Théo",
    lastname: "Niefer",
    description: "Support Specialist",
    tel: "+33 7 81 72 74 64",
    avatar: "https://avatars.hubspot.net/f7524ce30eb443f5a1bda2fe095a50ee-80",
    cegidCode: "TNI",
  },
  43367369: {
    id: 7,
    email: "malik@arioneo.com",
    firstname: "Malik",
    lastname: "Akkus",
    description: "International Sales",
    tel: "+90 536 832 63 70",
    avatar:
      "https://api-na1.hubspot.com/userpreferences/v1/avatar/0717784ca5703093ef733c813acbc4f3/144", //Placeholder
    cegidCode: "MAK",
  },
  137471626: {
    id: 8,
    email: "sheri@arioneo.com",
    firstname: "Sheri",
    lastname: "Graham",
    description: "US Sales Specialist",
    tel: "+1 (859) 475 9511",
    avatar: "https://avatars.hubspot.net/19b55edbada7176e67b00bb331ab9642-100",
    cegidCode: "SGA",
  },
  139697534: {
    id: 9,
    email: "athina@arioneo.com",
    firstname: "Athina",
    lastname: "Miliotis",
    description: "Représentante Commercial France",
    tel: "+33 6 69 73 74 01",
    avatar:
      "https://api-na1.hubspot.com/userpreferences/v1/avatar/0717784ca5703093ef733c813acbc4f3/144", //Placeholder
    cegidCode: "AML",
  },
  155760196: {
    id: 10,
    email: "jerome@arioneo.com",
    firstname: "Jerome",
    lastname: "Thibeault",
    description: "Représentant Commercial France",
    tel: "+336 60 37 97 60",
    avatar:
      "https://api-na1.hubspot.com/userpreferences/v1/avatar/0717784ca5703093ef733c813acbc4f3/144", //Placeholder
    cegidCode: "JTH",
  },
  155761524: {
    id: 11,
    email: "stephanie@arioneo.com",
    firstname: "Stephanie",
    lastname: "Prunault",
    description: "Représentante Commerciale France",
    tel: "+33 6 60 37 97 90",
    avatar:
      "https://api-na1.hubspot.com/userpreferences/v1/avatar/0717784ca5703093ef733c813acbc4f3/144", //Placeholder
    cegidCode: "SPR",
  },
  107883178: {
    id: 12,
    email: "clarisse@arioneo.com",
    firstname: "Clarisse",
    lastname: "Levy",
    description: "Sales Team",
    tel: "+33 6 86 60 27 12",
    avatar:
      "https://api-na1.hubspot.com/userpreferences/v1/avatar/0717784ca5703093ef733c813acbc4f3/144", //Placeholder
    cegidCode: "CLV",
  },
  194955926: {
    id: 13,
    email: "baptiste@arioneo.com",
    firstname: "Baptiste",
    lastname: "Hebbrecht",
    description: "Data Success Manager",
    tel: "+33 6 63 38 06 19",
    avatar:
      "https://api-na1.hubspot.com/userpreferences/v1/avatar/3f4f07e870415735497d8d158fbb36b1/144", //Placeholder
    cegidCode: "BHE",
  },
};
