const lang = {
    "test": "Ceci est un test",
    "reference": "Référence",
    "for": "Pour",
    "comments": "Commentaires",
    "quote_default_title_equimetre": "Bon de commande Equimetre",
    "quote_default_title_equisym": "Bon de commande Equisym",
    "line_items_title": "Produits & Services",
    "price_summary_discount": " de remise",
    "per_year": "/ an",
    "per_month": "/ mois",
    "here": "ici",
    "user_forms_title": "Formulaire de finalisation de commande",
    "user_forms_subtitle": "Merci de remplir les informations suivantes pour valider votre commande chez Arioneo. Pour toute question n'hésitez pas à téléphoner à votre commercial.",
    "user_forms_your_email": "Votre E-mail",
    "user_forms_equimetre_title": "Coordonnées du propriétaire du compte Equimetre : qui sera en charge des données ?",
    "user_forms_equimetre_subtitle": "Ces informations sont celles de la personne qui se connectera à la plateforme Equimetre. Nous les utilisons pour créer votre compte et associer vos Equimetre à ce même compte. Un email sera envoyé à l'adresse du compte pour l'activer en choisissant un mot de passe.",
    "user_forms_equimetre_email": "Email de connexion au compte Equimetre",
    "user_forms_equimetre_firstname": "Prénom associé au compte Equimetre",
    "user_forms_equimetre_lastname": "Nom associé au compte Equimetre",
    "user_forms_equimetre_role": "Qui est la personne qui se connectera avec ce compte?",
    "user_forms_equimetre_role_placeholder": "Merci de sélectionner le rôle qui se rapproche le plus de celui de la personne",

    "user_forms_equisym_title": "Coordonnées du propriétaire du compte Equisym.",
    "user_forms_equisym_subtitle": "Ces informations sont celles de la personne qui se connectera à la plateforme Equisym. Nous les utilisons pour créer votre compte et associer vos valises Equisym à ce même compte.",
    "user_forms_equisym_email": "Email de connexion au compte Equisym",
    "user_forms_equisym_firstname": "Prénom associé au compte Equisym",
    "user_forms_equisym_lastname": "Nom associé au compte Equisym",
    "user_forms_equisym_role": "Qui est la personne qui se connectera avec ce compte?",
    "user_forms_equisym_role_placeholder": "Merci de sélectionner le rôle qui se rapproche le plus de celui de la personne",

    "user_forms_billing_title": "Informations de facturation: qui sera en charge des factures ?",
    "user_forms_billing_subtitle": "Ces informations seront transmises à notre service comptabilité et seront réutilisées pour les futurs sujets de facturation.",
    "user_forms_billing_firstname": "Prénom du contact de facturation",
    "user_forms_billing_lastname": "Nom du contact de facturation",
    "user_forms_billing_company": "Société facturée",
    "user_forms_billing_email": "E-mail du contact de facturation",
    "user_forms_billing_phone": "Téléphone du contact de facturation",
    "user_forms_billing_addressline1": "Adresse de facturation ligne 1",
    "user_forms_billing_addressline2": "Adresse de facturation ligne 2",
    "user_forms_billing_addressline3": "Adresse de facturation ligne 3",
    "user_forms_billing_zipcode": "Code postal de facturation",
    "user_forms_billing_city": "Ville de facturation",
    "user_forms_billing_country": "Pays de facturation",
    "user_forms_billing_state_code": "Code province (USA uniquement)",
    "user_forms_billing_country_placeholder": "Veuillez sélectionner",
    "user_forms_billing_vatnumber_title": "Numéro de TVA intra-communautaire",
    "user_forms_billing_vatnumber_subtitle": "Pour les pays de l'Union Européenne, sauf France, vous devez entrer votre numéro de TVA pour qu'elle ne soit pas facturée.",

    "user_forms_billing_to_delivery_checkbox_title": "Coordonnées de livraison identiques aux coordonnées de facturation",
    "user_forms_billing_to_equimetre_checkbox_title": "Coordonnées du compte equimetre.com identiques aux coordonnées de facturation",

    "user_forms_delivery_title": "Informations de livraison: : qui recoit le colis ?",
    "user_forms_delivery_subtitle": "Ces informations sont celles de la personne qui sera en charge de la réception des paquets. Nous lui communiquerons toutes les mises à jour relatives à l'envoi de la commande.",
    "user_forms_delivery_firstname": "Prénom du contact de livraison",
    "user_forms_delivery_lastname": "Nom du contact de livraison",
    "user_forms_delivery_company": "Société pour la livraison",
    "user_forms_delivery_email": "E-mail de suivi de livraison",
    "user_forms_delivery_phone": "Téléphone de suivi de livraison",
    "user_forms_delivery_addressline1": "Adresse de livraison ligne 1",
    "user_forms_delivery_addressline2": "Adresse de livraison ligne 2",
    "user_forms_delivery_addressline3": "Adresse de livraison ligne 3",
    "user_forms_delivery_zipcode": "Code postal de livraison",
    "user_forms_delivery_city": "Ville de livraison",
    "user_forms_delivery_country": "Pays de livraison",
    "user_forms_delivery_country_placeholder": "Veuillez sélectionner",
    "user_forms_delivery_state_code": "Code province (US seulement)",

    "user_forms_requested_field": "Ce champ est requis",
    "user_forms_complete_all_required_fields": "Merci de remplir tous les champs requis.",
    "user_forms_wrong_tva_number": "Numéro de TVA invalide (il doit être composé de 2 lettres puis de chiffres).",

    "payment_method_title": "Methode de paiement",
    "payment_method_subtitle": "Selectionnez votre moyent de paiement",
    "payment_method_credit_card": "Carte banquaire",
    "payment_method_sepa": "Mandat SEPA (Europe uniquement)",
    "payment_method_bank_transfer": "Virement",
    "payment_method_check": "Chèque",

    "purchase_terms_title": "Conditions d'achat",
    "purchase_terms_terms_of_payment": "Conditions de règlement",
    "purchase_terms_line1": "Premier paiement à la signature du devis, solde avant livraison.",
    "purchase_terms_line2": "Futurs paiements à la date d’anniversaire annuelle ou mensuelle de l’abonnement. Si le type d’abonnement est mensuel, le Client s’engage sur une durée de neuf (9) mois.",
    "purchase_terms_line3": "La Société (ARIONEO) LIM FRANCE, Société par actions simplifiée au capital de 515 665,78 Euros immatriculée au Registre du Commerce et des Sociétés de Périgueux sous le numéro 420 738 577, dont le siège social est situé NONTRON (24300), Bellevue, chemin Fontaine de Fanny.",
    "purchase_terms_terms_and_conditions": "Conditions Générales de Vente",
    "purchase_terms_acceptance_title": "Vous devez accepter, lire et comprendre les Conditions Générales de Vente",
    "purchase_terms_acceptance_subtitle": "En cochant cette case, j'accepte les",
    "purchase_terms_acceptance_checked" : "En validant votre bon de commande, vous avez accepté les",

    "quote_total_recurring_discount": "Remise récurrente",
    "quote_total_recurring_subtotal": "Sous-total récurrent",
    "quote_total_onetime_discount": "Remise unique",
    "quote_total_onetime_subtotal": "Sous-total unique",
    "quote_total_vat": "TVA 20%",
    "quote_total_total_novat": "Total HT",
    "quote_total_total": "Total TTC",
    "quote_total_expiration": "Ce devis expire le",
    "quote_deposit": "Acompte à régler (CB / SEPA uniquement) pour valider votre commande",

    "actions_checkout": "Valider ce bon de commande et Payer",
    "actions_checkout_subtitle": "Cette opération peut prendre plusieurs secondes ...",
    "actions_in_progress": "Opérations en cours ... Merci de patienter.",

    "actions_save": "Sauvegarder pour plus tard",
    "actions_save_results": "Informations sauvegardées",

    "footer_title": "Des questions ? Contactez-moi",
    "footer_download_quote": "Télécharger le devis",
    "footer_print_quote": "Imprimer le devis",

    "bank_transfert_title": "Virement bancaire",
    "bank_transfert_bank_details": "Détails",
    "bank_transfert_thanks": "Merci pour votre commande, elle sera expédiée une fois que le virement sera recu.",
    "bank_transfert_bank_address": "Adresse de la banque",
    "bank_transfert_order": "Veuillez indiquer la référence de virement",
    "bank_transfert_account_name": "Titulaire du compte",
    "bank_transfert_wrong_method": "Si vous souhaitez changer de moyen de paiement, merci de contacter votre commercial.",

    "following_subtitle": "Merci, votre commande est validée.",
    "following_order_title": "Statut de livraison",
    "following_order_subtitle_preparing": "Votre commande est en cours de préparation...",
    "following_order_subtitle_sent": "Votre commande a été envoyée, vous pouvez suivre le colis ici:",
    "following_order_button_tracking": "Lien de suivi",

    "following_invoice_title": "Factures",
    "following_invoice_subtitle_unavailable": "Votre facture n'est pas encore disponible",
    "following_invoice_subtitle_available": "Votre facture est disponible ici:",
    "following_invoice_subtitle_email": "Vos factures sont envoyées par email à cette adresse:",

    "quote_expired": "Votre devis a expiré.",

    "iban_fr": "FR76 3000 4025 6100 0114 2499 322",
    "iban_us": "FR76 3000 4007 1400 0100 0203 486",
    "iban_uk": "FR76 3000 4024 9700 0100 3738 387",

    "bic_fr": "BNPAFRPPXXX",
    "bic_us": "BNPAFRPPXXX",
    "bic_uk": "BNPAFRPPCTO",

};

export default lang;