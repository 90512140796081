import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useParams } from "react-router";
//CSS
import "../styles/App.css";
//COMPONENTS
import Page from "./Page.js";

function Deal() {
  let { dealId } = useParams();

  return <Page dealId={dealId}></Page>;
}

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:dealId" children={<Deal />} />
      </Switch>
    </Router>
  );
}

export default App;
