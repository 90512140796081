//COMPONENTS
import Trans from "../components/Trans.js";

// -----------------------------------------------------------------
//        UTILS
// -----------------------------------------------------------------

export function textFrequencyFromHs(recurringbillingfrequency) {
  switch (recurringbillingfrequency) {
    case "annually":
      return <Trans text="per_year"></Trans>;
    case "monthly":
      return <Trans text="per_month"></Trans>;
    default:
      return "";
  }
}

export function getPriceCents(price) {
  const number = Number(price);
  const fixed = number.toFixed(2);
  return fixed;
}

export function getCurrencyText(price, currencyCode) {
  if (currencyCode === "") {
    return "";
  }
  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currencyCode,
  }).format(price);
}
