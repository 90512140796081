const lang = {
    "test": "This is a test",
    "reference": "Reference",
    "for": "For",
    "comments": "Comments",
    "quote_default_title_equimetre": "Equimetre Purchase Order",
    "quote_default_title_equisym": "Equisym Purchase Order",
    "line_items_title": "Products & Services",
    "price_summary_discount": "discount",
    "per_year": "/ year",
    "per_month": "/ month",
    "here": "here",
    "user_forms_title": "Order completion form",
    "user_forms_subtitle": "Please fill in the following information to validate your order with Arioneo. If you have any questions, do not hesitate to call your sales representative.",
    "user_forms_your_email": "Your E-mail",

    "user_forms_equimetre_title": "Details of the Equimetre account owner: who will be in charge of the data?",
    "user_forms_equimetre_subtitle": "These are the details of the person who will log in to the Equimetre platform. We use it to create your account and to associate your Equimetre to this same account. An email will be sent to the account address to activate it by choosing a password.",
    "user_forms_equimetre_email": "Email attached to the Equimetre account",
    "user_forms_equimetre_firstname": "First name attached to the Equimetre account",
    "user_forms_equimetre_lastname": "Last name attached to the Equimetre account",
    "user_forms_equimetre_role": "Who will be the person in charge of the account?",
    "user_forms_equimetre_role_placeholder": "Please select the role that most closely matches the person's",

    "user_forms_equisym_title": "Details of the Equisym account owner.",
    "user_forms_equisym_subtitle": "These are the details of the person who will log in to the Equisym platform. We use it to create your account and to associate your Equisym to this same account.",
    "user_forms_equisym_email": "Email attached to the Equisym account",
    "user_forms_equisym_firstname": "First name attached to the Equisym account",
    "user_forms_equisym_lastname": "Last name attached to the Equisym account",
    "user_forms_equisym_role": "Who will be the person in charge of the account?",
    "user_forms_equisym_role_placeholder": "Please select the role that most closely matches the person's",

    "user_forms_billing_title": "Billing information: who will be in charge of the bills?",
    "user_forms_billing_subtitle": "This information will be forwarded to our accounting department and will be used for future billing purposes.",
    "user_forms_billing_firstname": "First name for billing",
    "user_forms_billing_lastname": "Last name for billing",
    "user_forms_billing_company": "Billed Company",
    "user_forms_billing_email": "E-mail for billing matters",
    "user_forms_billing_phone": "Phone number for billing matters (compulsory)",
    "user_forms_billing_addressline1": "Billing address line 1",
    "user_forms_billing_addressline2": "Billing address line 2",
    "user_forms_billing_addressline3": "Billing address line 3",
    "user_forms_billing_zipcode": "Billing zip code",
    "user_forms_billing_city": "Billing city",
    "user_forms_billing_country": "Billing country",
    "user_forms_billing_country_placeholder": "Please Select",
    "user_forms_billing_state_code": "Billing state code (US only)",
    "user_forms_billing_vatnumber_title": "Intra community VAT number",
    "user_forms_billing_vatnumber_subtitle": "Only for EU countries, except France, you must enter your VAT number so that it is not invoiced",

    "user_forms_billing_to_delivery_checkbox_title": "Shipping info identical to billing info",
    "user_forms_billing_to_equimetre_checkbox_title": "Equimetre account info identical to billing info",

    "user_forms_delivery_title": "Shipping details",
    "user_forms_delivery_subtitle": "This information is for the person who will be in charge of receiving the packages. We will communicate to him/her all updates related to the shipment of the order. ",
    "user_forms_delivery_firstname": "First name for delivery",
    "user_forms_delivery_lastname": "Last name for delivery",
    "user_forms_delivery_company": "Company for delivery",
    "user_forms_delivery_email": "Email for delivery updates",
    "user_forms_delivery_phone": "Phone number for delivery updates",
    "user_forms_delivery_addressline1": "Shipping address line 1",
    "user_forms_delivery_addressline2": "Shipping address line 2",
    "user_forms_delivery_addressline3": "Shipping address line 3",
    "user_forms_delivery_zipcode": "Zip code shipping address",
    "user_forms_delivery_city": "City shipping address",
    "user_forms_delivery_country": "Country shipping address",
    "user_forms_delivery_country_placeholder": "Please Select",
    "user_forms_delivery_state_code": "Shipping state code (US only)",

    "user_forms_requested_field": "This field is mandatory",
    "user_forms_complete_all_required_fields": "Please complete all required fields.",
    "user_forms_wrong_tva_number": "Invalid VAT number (it must be composed of 2 letters then numbers).",

    "payment_method_title": "Payment Method",
    "payment_method_subtitle": "Select your payment method",
    "payment_method_credit_card": "Credit card",
    "payment_method_sepa": "SEPA mandate (Europe only)",
    "payment_method_bank_transfer": "Bank transfer",
    "payment_method_check": "Check",

    "purchase_terms_title": "Purchase Terms",
    "purchase_terms_terms_of_payment": "Terms of payment",
    "purchase_terms_line1": "First payment on signature of quotation, balance upon delivery.",
    "purchase_terms_line2": "Next payments on the annual or monthly anniversary date of the subscription. If the type of subscription is monthly, the Customer commits to a duration of nine (9) months.",
    "purchase_terms_line3": "(ARIONEO) LIM FRANCE, a simplified stock company, with a capital of 515 665,78 euros, registered at the Périgueux (France) Trade and Companies Register, under the number 420 738 577, which has its headquarters located at NONTRON (24300), Bellevue, chemin Fontaine de Fanny (hereinafter “the Company”).",
    "purchase_terms_terms_and_conditions": "General Terms and Conditions",

    "purchase_terms_acceptance_title": "You must accept, read and understand the General Terms and Conditions",
    "purchase_terms_acceptance_subtitle": "By checking this box, I accept the",
    "purchase_terms_acceptance_checked": "By validating your order form, you have accepted the",

    "quote_total_recurring_discount": "Recurring discount",
    "quote_total_recurring_subtotal": "Recurring subtotal",
    "quote_total_onetime_discount": "One-time discount",
    "quote_total_onetime_subtotal": "One-time subtotal",
    "quote_total_vat": "20% VAT",
    "quote_total_total_novat": "Total (excl. VAT)",
    "quote_total_total": "Total (incl. VAT)",
    "quote_total_expiration": "This quote expires on",
    "quote_deposit": "Deposit to confirm your order (Credit Card or SEPA only)",

    "actions_checkout": "Validate this purchase order and Pay",
    "actions_checkout_subtitle": "This operation may take several seconds ...",
    "actions_in_progress": "In progress ... Please wait.",

    "actions_save": "Save for later",
    "actions_save_results": "Save details with success",

    "footer_title": "Questions? Contact me",
    "footer_download_quote": "Download quote",
    "footer_print_quote": "Print quote",

    "bank_transfert_title": "Bank transfer",
    "bank_transfert_bank_details": "Details",
    "bank_transfert_thanks": "Thank you for your order, it will be shipped once the transfer is received.",
    "bank_transfert_bank_address": "Bank address",
    "bank_transfert_order": "Please indicate the following transfer reference",
    "bank_transfert_account_name": "Account name",
    "bank_transfert_wrong_method": "If you wish to change your payment method, please contact your sales representative.",

    "following_subtitle": "Thanks, your order is validated.",
    "following_order_title": "Delivery status",
    "following_order_subtitle_preparing": "Your order is being prepared...",
    "following_order_subtitle_sent": "Your order has been shipped. You can track the parcel here:",
    "following_order_button_tracking": "Tracking link",

    "following_invoice_title": "Invoices",
    "following_invoice_subtitle_unavailable": "Your invoice is not yet available",
    "following_invoice_subtitle_available": "Your invoice is available here:",
    "following_invoice_subtitle_email": "Your invoices will be sent by email to the address:",

    "quote_expired": "Your quote has expired.",

    "iban_fr": "FR76 3000 4025 6100 0114 2499 322",
    "iban_us": "FR76 3000 4007 1400 0100 0203 486",
    "iban_uk": "FR76 3000 4024 9700 0100 3738 387",

    "bic_fr": "BNPAFRPPXXX",
    "bic_us": "BNPAFRPPXXX",
    "bic_uk": "BNPAFRPPCTO",

};

export default lang;