import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en.js";
import fr from "./fr.js";

// the translations
const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: options,
    resources,
    fallbackLng: "en", // use en if detected lng is not available
    supportedLngs: ["en", "fr"],
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
